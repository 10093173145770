import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// export const downloadAsPDF = (htmlContent, fileName = "sample_file.pdf", margins = { left: 15, top: 15 }) => {
//   const doc = new jsPDF();

//   const specialElementHandlers = {
//     "#editor": function (element, renderer) {
//       return true;
//     },
//   };

//   doc.fromHTML(htmlContent, margins.left, margins.top, {
//     width: 700,
//     elementHandlers: specialElementHandlers,
//   });

//   doc.save(fileName);
// };

export const downloadAsPDF = async (el) => {
  let doc;
  doc = new jsPDF();
  //   const el = document.getElementById("content-report");

  if (el instanceof Object && el !== null) {
    const canvas = await html2canvas(el);
    const imgData = canvas.toDataURL("image/png");

    doc.addImage(imgData, "PNG", 15, 15, 170, 0);


    // if (typeof pdf === "string" && pdf.length > 0) {
    //   setPdf(pdf);
    // }
  }
  doc.save("Report.pdf");
};
