import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { addDays } from 'date-fns';

export default function IndividualUserEdit() {
  const Navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [errorPersonalStyle, setErrorPersonalStyle] = useState({
    color: "white",
    display: "none",
  });
  const [successPersonalStyle, setSuccessPersonalStyle] = useState({
    color: "black",
    display: "none",
  });

  const [successPersonal, setSuccessPersonal] = useState();
  const [errorPersonal, setErrorPersonal] = useState([]);

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");

  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [averageFitness, setAverageFitness] = useState("");
  const [gender, setGender] = useState("");
  const [injury, setInjury] = useState("");
  const [displayInjury, setDisplayInjury] = useState({
    display: "none",
  });
  const [hipInjuryOptions, setHipInjuryOptions] = useState(0);
  const [kneeInjuryOptions, setKneeInjuryOptions] = useState(0);
  const [backInjuryOptions, setBackInjuryOptions] = useState(0);
  const [footInjuryOptions, setFootInjuryOptions] = useState(0);
  const handleChangeGender = (e) => setGender(e.target.value);
  const handleAverageFitnessChange = (e) => setAverageFitness(e.target.value);
  const [searchParams] = useSearchParams();
   
  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getUser = async () => {
    try {
      const response = await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/get-user",
          params,
          config
        )
        .then((response) => response.data)
        .catch((err) => console.error(err));
      setUserId(response.data.id);
      setUserName(response.data.name);
      setUserEmail(response.data.email);  
    } catch (err) {
      console.log(err);
    }
  };

  const updateUser = (e) => {
    e.preventDefault();
    const params = {
      id: userId,
      name: userName,
      email: userEmail,
      password: userPassword,
      c_password: userConfirmPassword,
      
    };

    
    fetch(process.env.REACT_APP_API_URL + "V1/user/update-user", {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("_token"),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        
        if (res.success) {
          setSuccess(res.message);
          setSuccessStyle({
            color: "black",
            display: "block",
          });
          setErrorStyle({
            color: "red",
            display: "none",
          });

          setTimeout(() => {
            Navigate("/individual-users");
          }, 2000);
        } else {
          setError(res.message);
          setErrorStyle({
            color: "red",
            display: "block",
          });
          setSuccessStyle({
            color: "black",
            display: "none",
          });
        }
      });
    
  };

  const getUserPersonalDetail = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/get-user-personal-detail",
          params,
          config
        )
        .then((response) => {
          setHeight(response.data.data.height);
          setWeight(response.data.data.weight);
          setAverageFitness(response.data.data.average_fitness);
          setGender(response.data.data.gender);
          setInjury(response.data.data.injury);
          setHipInjuryOptions(response.data.data.injury_hip);
          setKneeInjuryOptions(response.data.data.injury_knee);
          setBackInjuryOptions(response.data.data.injury_back);
          setFootInjuryOptions(response.data.data.injury_foot);
          setDate(new Date(response.data.data.dob));

          if (response.data.data.injury === true) {
            setDisplayInjury({
              display: "block",
            });
            
          } else {
            setDisplayInjury({
              display: "none",
            });
            
          }
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserPersonalDetail = async (e) => {
    e.preventDefault();

    let zero = "0";
    let sd = new Date(date);
    let sdm = sd.getMonth() + 1;
    if (sdm < 10) {
      sdm = zero.concat(sdm);
    }
    let birthDate = `${sd.getFullYear()}-${sdm}-${sd.getDate()} 00:00:00`;

    const params = {
      user_id: userId,
      height: height,
      weight: weight,
      dob: birthDate,
      average_fitness: averageFitness,
      gender: gender,
      injury: injury,
      injury_hip: hipInjuryOptions,
      injury_knee: kneeInjuryOptions,
      injury_back: backInjuryOptions,
      injury_foot: footInjuryOptions,
    };

    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/update-user-personal-detail",
          params,
          config
        )
        .then(function (response) {
          if (response.data.success) {
            setSuccessPersonal(response.data.message);
            setSuccessPersonalStyle({
              color: "black",
              display: "block",
            });
            setErrorPersonalStyle({
              color: "red",
              display: "none",
            });

            setTimeout(() => {
            Navigate("/individual-users");
          }, 2000);
            
          }
        })
        .catch(function (err) {
          setErrorPersonal(err.response.data.data);
          setErrorPersonalStyle({
            color: "red",
            display: "block",
          });
          setSuccessPersonalStyle({
            color: "black",
            display: "none",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUser();
    getUserPersonalDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Individual User</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/individual-users">Individual User</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Individual User
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (error.name) {
                          return <li>{error.name}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.email) {
                          return <li>{error.email}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.password) {
                          return <li>{error.password}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.c_password) {
                          return <li>{error.c_password}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={updateUser}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputName"
                          placeholder="Enter Name"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword2">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword2"
                          placeholder="Confirm Password"
                          value={userConfirmPassword}
                          onChange={(e) =>
                            setUserConfirmPassword(e.target.value)
                          }
                        />
                      </div>
                     
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h1 className="m-0">Edit Personal Detail</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorPersonalStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (errorPersonal.height) {
                          return <li>{errorPersonal.height}</li>;
                        }
                      })()}
                      {(() => {
                        if (errorPersonal.weight) {
                          return <li>{errorPersonal.weight}</li>;
                        }
                      })()}
                      {(() => {
                        if (errorPersonal.dob) {
                          return <li>{errorPersonal.dob}</li>;
                        }
                      })()}
                      {(() => {
                        if (errorPersonal.average_fitness) {
                          return <li>{errorPersonal.average_fitness}</li>;
                        }
                      })()}
                      {(() => {
                        if (errorPersonal.gender) {
                          return <li>{errorPersonal.gender}</li>;
                        }
                      })()}
                      {(() => {
                        if (errorPersonal.injury) {
                          return <li>{errorPersonal.injury}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successPersonalStyle}>
                    {successPersonal}
                  </div>
                  <form autoComplete="off" onSubmit={updateUserPersonalDetail}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputHeight">Height</label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputHeight"
                              placeholder="Enter Height"
                              value={height}
                              onChange={(e) => setHeight(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputWeight">Weight</label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputWeight"
                              placeholder="Enter Weight"
                              value={weight}
                              onChange={(e) => setWeight(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="inputDob">Birth Date</label>
                            
                            <ReactDatePicker
                              id="inputDob"
                              className="form-control"
                              selected={date}
                              onChange={(date) => setDate(date)}
                              dateFormat="yyyy-MM-dd"
                              maxDate={addDays(new Date(), 0)}
                              wrapperClassName="datePicker"
                              
                            />

                                
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="inputAverageFitness">
                              Average Fitness
                            </label>
                            <select
                              className="form-control"
                              value={averageFitness}
                              onChange={handleAverageFitnessChange}
                            >
                              <option value="">Select</option>
                              <option value="1">Low</option>
                              <option value="2">Moderate</option>
                              <option value="3">High</option>
                              <option value="4">Athlete</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputWeight">Gender</label>
                            <select
                              className="form-control"
                              value={gender}
                              onChange={handleChangeGender}
                            >
                              <option value="">Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputWeight">Injury</label>
                              
                              <select
                              className="form-control"
                              value={injury}
                              onChange={(e) => {
                                setInjury(e.target.value);  
                                
                                if (e.target.value === "true") {
                                  setDisplayInjury({
                                    display: "block",
                                  });
                                } else {
                                   
                                  setDisplayInjury({
                                    display: "none",
                                  });
                                }  
                              }}
                            > 
                              <option value="">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6" style={displayInjury}>
                          <div className="form-group">
                            <label htmlFor="exampleInputWeight">
                              Hip Injury
                            </label>
                            <select
                              className="form-control"
                              value={hipInjuryOptions}
                              onChange={(e) => {
                                setHipInjuryOptions(e.target.value);
                              }}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6" style={displayInjury}>
                          <div className="form-group">
                            <label htmlFor="exampleInputWeight">
                              Knee Injury
                            </label>
                            <select
                              className="form-control"
                              value={kneeInjuryOptions}
                              onChange={(e) => {
                                setKneeInjuryOptions(e.target.value);
                              }}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6" style={displayInjury}>
                          <div className="form-group">
                            <label htmlFor="exampleInputWeight">
                              Back Injury
                            </label>
                            <select
                              className="form-control"
                              value={backInjuryOptions}
                              onChange={(e) => {
                                setBackInjuryOptions(e.target.value);
                              }}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6" style={displayInjury}>
                          <div className="form-group">
                            <label htmlFor="exampleInputWeight">
                              Foot Injury
                            </label>
                            <select
                              className="form-control"
                              value={footInjuryOptions}
                              onChange={(e) => {
                                setFootInjuryOptions(e.target.value);
                              }}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
