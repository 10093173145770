import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function BlogDetail() {
  const [blogTitle, setBlogTitle] = useState();
  const [blogContent, setBlogContent] = useState("");

  const [searchParams] = useSearchParams();

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getBlog = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/blog/get-blog",
          params,
          config
        )
        .then((response) => {
          setBlogTitle(response.data.data.title);
          setBlogContent(response.data.data.content);
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBlog();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Blog Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    <a href="/blog/all">Blogs</a>
                  </li>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Link to="/blog/edit?id=1" className="btn btn-primary mb-2">
                      Edit
                    </Link>
                    <table className="table table-striped">
                      <tr>
                        <td>Title</td>
                        <td>{blogTitle}</td>
                      </tr>{" "}
                      <tr>
                        <td>Content</td>
                        <td>{blogContent}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
