import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function ChallengeDetail() {
  const [challengeId, setChallengeId] = useState();
  const [challangeName, setChallangeName] = useState();
  const [challangePic, setChallangePic] = useState();
  const [challangeStartDate, setChallangeStartDate] = useState(null);
  const [challangeEndDate, setChallangeEndDate] = useState(null);
  const [challangeGoal, setChallangeGoal] = useState("");
  const [,setChallangeFrequency] = useState("");
  const [challangeType, setChallangeType] = useState("");
  const [type, setType] = useState("");
  const [challengeDescription, setChallengeDescription] = useState("");
  const [charityName, setCharityName] = useState("");
  const [challengeCode, setChallengeCode] = useState("");
  const [challengeCreatedBy, setChallengeCreatedBy] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [challengeActivityGoal, setChallengeActivityGoal] = useState("");
  const [challengeNumberOfGoal, setChallengeNumberOfGoal] = useState("");

  const [searchParams] = useSearchParams();

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getChallenge = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/challenge/get-challenge",
          params,
          config
        )
        .then((response) => {
          setChallengeId(response.data.data.id);
          setChallangeName(response.data.data.name);
          setChallangeStartDate(response.data.data.start_date);
          setChallangeEndDate(response.data.data.end_date);
          setChallangeGoal(response.data.data.goal);
          setChallangeFrequency(response.data.data.frequency);
          setChallangePic(response.data.data.image);
          setChallangeType(response.data.data.challenge_type);
          setType(response.data.data.type);
          setChallengeDescription(response.data.data.description);
          setCharityName(response.data.data.charity_name);
          setChallengeCode(response.data.data.code);
          setChallengeCreatedBy(response.data.data.created_by);
          setPaymentMethod(response.data.data.paymentmethod);
          setChallengeActivityGoal(response.data.data.activity_goal);
          setChallengeNumberOfGoal(response.data.data.no_of_goal);
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getChallenge();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPaymentMethod = () => {
    switch (paymentMethod) {
      case 1:
        return "Check";
      case 2:
        return "Credit Card";
      default:
        return "Unknown Payment Method";
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Challenge Detail</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/challenges">Challenges</Link>
                  </li>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Link
                      to={`/challenge/edit?id=${challengeId}`}
                      className="btn btn-primary mb-2"
                    >
                      Edit
                    </Link>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <td>Name</td>
                          <td>{challangeName}</td>
                        </tr>
                        <tr>
                          <td>Challenge Type</td>
                          <td>{challangeType}</td>
                        </tr>
                        {type === 1 ? (
                          <>
                            <tr>
                              <td>Charity Name</td>
                              <td>{charityName}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>Image</td>
                          <td>
                            {(() => {
                              if (challangePic !== "") {
                                return (
                                  <img
                                    alt=""
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      "/storage/" +
                                      challangePic
                                    }
                                    width="30%"
                                    height="40%"
                                  />
                                );
                              }
                            })()}
                          </td>
                        </tr>
                        {type === 1 || type === 4 ? (
                          <>
                            <tr>
                              <td>Start Date</td>
                              <td>{challangeStartDate}</td>
                            </tr>
                            <tr>
                              <td>End Date</td>
                              <td>{challangeEndDate}</td>
                            </tr>
                            <tr>
                              <td>Goal</td>
                              <td>{challangeGoal}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>Challenge Description</td>
                          <td>{challengeDescription}</td>
                        </tr>
                        <tr>
                          <td>Challenge Code</td>
                          <td>{challengeCode}</td>
                        </tr>
                        <tr>
                          <td>Created By</td>
                          <td>{challengeCreatedBy}</td>
                        </tr>
                        {type === 2 || type === 3 ? (
                          <>
                            <tr>
                              <td>Activity Goal</td>
                              <td>{challengeActivityGoal}</td>
                            </tr>
                            <tr>
                              <td>No. of Goal</td>
                              <td>{challengeNumberOfGoal}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {type === 1 || type === 4 ? (
                          <>
                            <tr>
                              <td>Payment Method</td>
                              <td>{renderPaymentMethod()}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
