import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TnCEdit() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
 
  const [tnCDescription, setTnCDescription] = useState("");
  const navigate = useNavigate();
 
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const fetchTnc = async (page) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `V1/tnc/fetch`,
        config
      );

      setTnCDescription(response.data.value);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.clear();
      } else {
        console.error("API error:", err.message);
      }
    }
  };

  const handleSubmit = (e) => {
    
    
    let formData = new FormData();
    formData.append("tncdescription", tnCDescription);

    axios
      .post(process.env.REACT_APP_API_URL + `V1/tnc/update`, formData, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSuccess(response.data.message);
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });
        setTimeout(() => {
          navigate("/"); 

        }, 3000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        console.log(error);
      });
  };

  useEffect(() => {
    fetchTnc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Update Term and Conditions</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/term-and-conditions">Term and Conditions</Link>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (error.name) {
                          return <li>{error.name}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.description) {
                          return <li>{error.description}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>

                  <ReactQuill
                    theme="snow"
                    value={tnCDescription}
                    onChange={setTnCDescription}
                  />

                  <div className="card-footer">
                    <button onClick={handleSubmit} className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
