import { postApiCall } from "../AxiosConfig/AxiosConfig";

export const getSpecificChallengeReport = (challengeId, userId, corporateId) => {
  return postApiCall(`V1/report/challenge-glance?challenge_id=${challengeId}&user_id=${userId}&corporate_id=${corporateId}`);
};

export const getIndividualTeamReport = (user_id, team_id, corporate_id) => {
  return postApiCall(`V1/report/individual-team-report?user_id=${user_id}&team_id=${team_id}&corporate_id=${corporate_id}`);
};

export const getOverallChallengeReport = (challenge_id, user_id, corporate_id) => {
  return postApiCall(`V1/report/overall-challenge?challenge_id=${challenge_id}&user_id=${user_id}&corporate_id=${corporate_id}`);
};

export const getTeamGlanceReport = (corporate_id, user_id) => {
  return postApiCall(`V1/report/team-glance?corporate_id=${corporate_id}&user_id=${user_id}`);
};

export const getQuickGlanceGraphsApi = (from_date, to_date, user_id, type, corporate_id) => {
  return postApiCall(`V1/report/quick-glance?from_date=${from_date}&to_date=${to_date}&user_id=${user_id}&type=${type}&corporate_id=${corporate_id}`);
};

export const getIndividualUserReport = (user_id, individual_user_id, from_date, to_date) => {
  return postApiCall(
    `V1/report/individual-user-report?user_id=${user_id}&individual_user_id=${individual_user_id}&from_date=${from_date}&to_date=${to_date}`
  );
};

export const getIndividualChallengeAchievementsReport = (challenge_id) => {
  return postApiCall(`V1/report/achievement?challenge_id=${challenge_id}`);
};

export const getSuperAdminDashboardData = () => {
  return postApiCall(`V1/report/admin-dashboard`);
};
