import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";

export default function Profile() {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState('fa fa-eye-slash');
  const [nType, setNType] = useState('password');
  const [nIcon, setNIcon] = useState('fa fa-eye-slash');

  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const params = {
    id: localStorage.getItem("id"),
  };

  const navigate = useNavigate();

  const fetchProfile = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/get-user",
          params,
          config
        )
        .then((response) => {
          setUserName(response.data.data.name);
          setUserEmail(response.data.data.email);
        })
        .then((response) => {});
    } catch (err) {
      if (err.response && err.response.status === 401) {
          localStorage.clear();
          navigate("/login"); 

        
      } else {
        console.error('API error:', err.message);
      }
    }
  };

  const handleToggle = () => {
     
    if (type==='password'){
       setIcon('fas fa-eye');
       setType('text')
    } else {
       setIcon('fa fa-eye-slash')
       setType('password')
    }
 }
 const handleNToggle = () => {
     
  if (nType==='password'){
     setNIcon('fas fa-eye');
     setNType('text')
  } else {
     setNIcon('fa fa-eye-slash')
     setNType('password')
  }
}


  useEffect(() => {
    fetchProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfile = (e) => {
    e.preventDefault();

    const params = {
      id: localStorage.getItem("id"),
      name: userName,
      email: userEmail,
      password: userPassword,
      c_password: userConfirmPassword,
    };

    axios
      .post(process.env.REACT_APP_API_URL + `V1/user/update-user`, params, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        
        setSuccess("Profile updated successfully");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });
        if(response.data.data.password_change === 1) {
          localStorage.clear();
          setTimeout(() => {
            navigate("/login"); 
          }, 5000);

        }
         
        
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
         
        console.log(error);
      });
  };

  return (
    <>
      <div className="content-wrapper" style={{ minHeight: "1604.8px" }}>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Profile</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">User Profile</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* /.col */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (error.name) {
                          return <li>{error.name}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.password) {
                          return <li>{error.password}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.confirm_password) {
                          return <li>{error.confirm_password}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    <h6>Success</h6>
                    <ul>
                      {(() => {
                        if (success) {
                          return <li>{success}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="tab-content">
                      <div className="tab-pane active" id="settings">
                      {2 === +localStorage.getItem("role") ? (
                        <>
                      <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-2 col-form-label"
                            >
                              Company ID
                            </label>
                            <div className="col-sm-10">
                            {(() => {
                              if (localStorage.getItem("corporateId") !== 'undefined') {
                                return (
                                  localStorage.getItem("corporateId")
                                );
                              }
                            })()}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-2 col-form-label"
                            >
                              Company Name
                            </label>
                            <div className="col-sm-10">
                            {(() => {
                              if (localStorage.getItem("corporateName") !== 'undefined') {
                                return (
                                  localStorage.getItem("corporateName")
                                );
                              }
                            })()}
                            </div>
                          </div>
                          </>
                          ) : (
                            ""
                          )}
                        
                        <form
                          className="form-horizontal"
                          onSubmit={updateProfile}
                        >
                          <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-2 col-form-label"
                            >
                              Name
                            </label>
                            <div className="col-sm-10">
                              <input
                                type="text"
                                className="form-control"
                                id="inputName"
                                placeholder="Name"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-2 col-form-label"
                            >
                              Email
                            </label>
                            <div className="col-sm-10">
                              <input
                                type="email"
                                className="form-control"
                                id="inputEmail"
                                placeholder="Email"
                                value={userEmail}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-2 col-form-label"
                            >
                              New Password
                            </label>
                            <div className="col-sm-10">
                              <input
                                type={type}
                                className="form-control"
                                id="inputPassword"
                                placeholder="New Password"
                                onChange={(e) =>
                                  setUserPassword(e.target.value)
                                }
                              />
                              <div className="input-group-append">
                                <div className="input-group-text">
                                  <span className={icon} onClick={handleToggle}/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-2 col-form-label"
                            >
                              Confirm Password
                            </label>
                            <div className="col-sm-10">
                              <input
                                type={nType}
                                className="form-control"
                                id="inputConfirmPassword"
                                placeholder="Confirm Password"
                                onChange={(e) =>
                                  setUserConfirmPassword(e.target.value)
                                }
                              />
                              <div className="input-group-append">
                                <div className="input-group-text">
                                  <span className={nIcon} onClick={handleNToggle}/>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="offset-sm-2 col-sm-10">
                              <button type="submit" className="btn btn-danger">
                                Update Profile
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* /.tab-pane */}
                    </div>
                    {/* /.tab-content */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}
