import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function CorporateUserCreate() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });

  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  //const [userPassword, setUserPassword] = useState("");
  //const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [depOptions, setDepOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const handleChangeDepartmentId = (e) => setDepartmentId(e.target.value);
  
  function createNewUser(e) {
    e.preventDefault();

    const params = {
      name: userName,
      email: userEmail,
      //password: userPassword,
      //c_password: userConfirmPassword,
      corporate_id: corpId,
      departmentId: departmentId,
    };

    fetch(process.env.REACT_APP_API_URL + "V1/user/create-new-corp-user", {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("_token"),
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {

          setSuccess(res.message + ", redirecting to listing...");
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setSuccessStyle({
            color: "black",
            display: "block",
          });

          setTimeout(() => {
            Navigate("/corporate-users");
          }, 2000);
        } else {
          setError(res.message);
          setErrorStyle({
            color: "red",
            display: "block",
          });
        }
      });
    return false;
  }

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const [options, setOptions] = useState([]);
  const [corpId, setCorpId] = useState("");
        
    useEffect(() => {
      if(localStorage.getItem("corporateId") !== 'undefined') {
        fetchDepartments(localStorage.getItem("corporateId")); // fetch page 1 of users
        setCorpId(localStorage.getItem("corporateId"));
      }
    }, []); 

    const handleChangeCorpId = (e) => {
      setCorpId(e.target.value);
      fetchDepartments(e.target.value);
      return false;  
    };

    const fetchDepartments = async (val) => {
      const params = {
        corporate_id: val,
      };
      
      fetch(process.env.REACT_APP_API_URL + "V1/departments/corporate", {
        method: "POST",
        body: JSON.stringify(params),
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setDepOptions(res.data);
        });
    };
   

  

  useEffect(() => {
    fetchCorporates(); // fetch page 1 of users
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCorporates = async (page) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/corporates/get-all-corporates`,
      config
    );

    setOptions(response.data.data);        
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Corporation User</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/corporate-users">Corporation Users</Link>
                </li>
                <li className="breadcrumb-item active">
                  Create Corporation User
                </li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.name) {
                        return <li>{error.name}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.email) {
                        return <li>{error.email}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.password) {
                        return <li>{error.password}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.c_password) {
                        return <li>{error.c_password}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.corporate_id) {
                        return <li>{error.corporate_id}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={createNewUser}>
                  <div className="card-body">
                {(() => {
                if (+localStorage.getItem("role") === 1) {
                  return (
                    <div className="form-group">
                      <div className="form-group">
                        <label>Corporation</label>
                        <select
                          className="form-control"
                          value={corpId}
                          onChange={handleChangeCorpId}
                        > <option value="">Select</option>
                          {options.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                      );
                    }
                  })()}

                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="department">Department</label>
                      <select
                        className="form-control"
                        value={departmentId}
                        onChange={handleChangeDepartmentId}
                      > <option value="">Select</option>
                        {depOptions.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
