import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function BadgesEdit() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });

  const [badgeId, setBadgeId] = useState("");
  const [badgeCategory, setBadgeCategory] = useState("");
  const [badgeAward, setBadgeAward] = useState("");
  const [badgeDescription, setBadgeDescription] = useState("");
  const [badgePic, setBadgePic] = useState("");

  const [searchParams] = useSearchParams();
  const [image, setImage] = useState("");
  const [imageStyle, setImageStyle] = useState({
    display: "none",
  });
  const [editImageStyle, setEditImageStyle] = useState({
    display: "block",
  });

  const Navigate = useNavigate();

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getBadge = async () => {
    try {
      const response = await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/badges/fetch",
          params,
          config
        )
        .then((response) => response.data)
        .catch((err) => console.error(err));
      setBadgeId(response.data.id);
      setBadgeCategory(response.data.category);
      setBadgeAward(response.data.award);
      setBadgeDescription(response.data.description);
      setBadgePic(response.data.image);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnChange = (e) => {
    setBadgePic(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageStyle({
      display: "block",
    });
    setEditImageStyle({
      display: "none",
    });
  };

  const updateBadge = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("id", badgeId);
    formData.append("description", badgeDescription);
    formData.append("image", badgePic);

    axios
      .post(process.env.REACT_APP_API_URL + `V1/badges/update`, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
         
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/badges/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getBadge();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeBadgeCategory = (e) => {
    setBadgeCategory(e.target.value);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Badge</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/badges/all">Badges</Link>
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                    {(() => {
                      if (error.category) {
                        return <li>{error.category}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.award) {
                        return <li>{error.award}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.description) {
                        return <li>{error.description}</li>;
                      }
                    })()}
                  </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={updateBadge}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputType">Category</label>
                        <select disabled
                          className="form-control"
                          id="inputType"
                          onChange={handleChangeBadgeCategory}
                          value={badgeCategory}
                        >
                          <option value="">Select Category</option>
                          <option value="Special Challenge">Special Challenge</option>
                          <option value="Action Completion">Action Completion</option>
                          <option value="Ranking/Achievement">Ranking/Achievement</option>
                          <option value="Social">Social</option>
                          <option value="Fitness Challenge">Fitness Challenge</option>
                          <option value="Habit Challenge">Habit Challenge</option>
                          <option value="For a cause">For a cause</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAward">Award</label>
                        <input disabled
                          type="text"
                          className="form-control"
                          id="inputAward"
                          placeholder="Enter Award"
                          value={badgeAward}
                          onChange={(e) => setBadgeAward(e.target.value)}
                        />
                      </div>{" "}
                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputDescription"
                          placeholder="Enter Award"
                          value={badgeDescription}
                          onChange={(e) => setBadgeDescription(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputImage">Image</label>
                        <input
                          type={"file"}
                          id={"exampleInputImage"}
                          onChange={handleOnChange}
                          className="form-control"
                        />
                        
                        {(() => {
                          if (badgePic !== '') {
                            return  <img alt="" src={process.env.REACT_APP_IMAGE_URL + '/storage/' + badgePic} width="10%" height="10%" style={editImageStyle}/>;
                          }
                        })()}
                        <img alt="" src={image} width="10%" height="10%" style={imageStyle} />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
