import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate,useSearchParams } from "react-router-dom";

export default function ChallengeCompanyInvite() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [, setPerPage] = useState(10);
  const [message] = useState("");
  const [showMessage] = useState(true);
 
  const [searchParams] = useSearchParams();
  const [id, setId] = useState([]);
  const Navigate = useNavigate();

  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [errorValidation, setErrorValidation] = useState([]);
  const [errorValidationStyle, setErrorValidationStyle] = useState({
    color: "white",
    display: "none",
  });
 
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const navigate = useNavigate();

  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    }
  ];

  const fetchUsers = async (page) => {
    setLoading(true);
    try { 
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-corp-users-for-invite?page=${page}&per_page=10&delay=1`,
      config
    );

    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
    }
    catch (error) {
      if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate("/login"); 
        
      } else {
        console.error('API error:', error.message);
      }
    }
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-corp-users-for-invite?page=${page}&per_page=${newPerPage}&delay=1`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage = 10, page = 1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/user/get-all-corp-users-for-invite?page=${page}&per_page=10&delay=1&search=${search}`,
      config
    );
    setTotalRows(response.data.total);
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSS = (state) => {
    setId(state.selectedRows);
  };

  const createNewChallenge = (e) => {
    e.preventDefault();

    let formData = new FormData();
    
    formData.append("id", JSON.stringify(id));
    formData.append("challenge_id", searchParams.get("id"));
   
    axios
      .post(process.env.REACT_APP_API_URL + `V1/user/send-company-invite`, formData, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        
        setSuccess(response.data.message);
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setErrorValidationStyle({
          color: "red",
          display: "none",
        });

        if(!response.data.success) {
          setSuccessStyle({
            color: "red",
            display: "block",
          });
        } 
        else {
          setSuccessStyle({
            color: "black",
            display: "block",
          });
        }
        setTimeout(() => {
          Navigate("/challenges");
        }, 2000);
        
      })
      .catch((error) => {
        
        if(error.response.data.message) {
          setError(error.response.data.message);
          setErrorValidationStyle({
            color: "red",
            display: "none",
          });
          setErrorStyle({
            color: "red",
            display: "block",
          });
        }
        if(error.response.data.message_validation) {
          setErrorValidation(error.response.data.message_validation);
          setErrorStyle({
            color: "red",
            display: "none",
          });
          setErrorValidationStyle({
            color: "red",
            display: "block",
          });

        }
        
         setSuccessStyle({
          color: "black",
          display: "none",
        });
        
        console.log(error);
      });   
  };

 

  ////////////////////////////////
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Company Invite challenge</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/challenges">Challenge</a>
                  </li>
                  <li className="breadcrumb-item active">Company Invite challenge</li>
                </ol>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                <div className="card-body" style={errorStyle}>
                  {(() => {
                    if (error) {
                      return <li>{error}</li>;
                    }
                  })()}
              </div>
              <div className="card-body" style={errorValidationStyle}> 
              {(() => {
                if (errorValidation.challenge_id) {
                  return <li>{errorValidation.challenge_id}</li>;
                }
              })()}
              {(() => {
                if (errorValidation.id) {
                  return <li>{errorValidation.id}</li>;
                }
              })()}
            </div>
            <div className="card-body" style={successStyle}>
              {(() => {
                if (success) {
                  return <>{success}</>;
                }
              })()}
            </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        {showMessage ? <p>{message}</p> : <></>}
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => handleSearcFilter(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-5"> </div>
                      <div className="col-md-5 mr-0">
                        <form onSubmit={createNewChallenge}>
                          <button className="btn btn-primary float-right" type="submit">
                            Send Invite
                          </button>
                        </form>
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      selectableRows = {1}
                      onSelectedRowsChange={handleSS}
                      paginationRowsPerPageOptions={[10,20,50,100]}
                      paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'ALL' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
