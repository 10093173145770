import { Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function BadgesCreate() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [imageStyle, setImageStyle] = useState({
    display: "none",
  });
  const [image, setImage] = useState("");
  const [badgeCategory, setBadgeCategory] = useState("");
  const [badgeAward, setBadgeAward] = useState("");
  const [badgeDescription, setBadgeDescription] = useState("");
  const [searchParams] = useSearchParams();
  const [selectedNameId, setSelectedNameId] = useState("");
  const [searchQuery] = useState("");
  const [names, setNames] = useState([]);
  const [badgesPic, setBadgesPic] = useState();
  const Navigate = useNavigate();
  const handleOnChange = (e) => {
    setBadgesPic(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageStyle({
      display: "block",
    });
  };

  const createBadge = (e) => {
    e.preventDefault();

    e.preventDefault();

    let formData = new FormData();
    formData.append("category", badgeCategory);
    formData.append("award", badgeAward);
    formData.append("description", badgeDescription);
    formData.append("image", badgesPic);
    formData.append("challenge_id", selectedNameId);

    axios
      .post(process.env.REACT_APP_API_URL + `V1/badges/add`, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/badges/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        console.log(error);
      });
  };

  const fetchChallenges = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `V1/challenges/get-all-challenges-for-badge`,
        config
      );

      setNames(response.data.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.error("API error:", err.message);
      }
    }
  };

  const handleChangeStreakCategory = (e) => {
    setBadgeCategory(e.target.value);
  };

  const filteredNames = names.filter((name) =>
    name.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchChallenges();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Create Badges</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/badges/all">Badges</Link>
                  </li>
                  <li className="breadcrumb-item active">Badge</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (error.challenge_id) {
                          return <li>{error.challenge_id}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.category) {
                          return <li>{error.category}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.action) {
                          return <li>{error.action}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.award) {
                          return <li>{error.award}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.description) {
                          return <li>{error.description}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={createBadge}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />

                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="nameSearch">Select Challenge</label>
                        <div className="custom-dropdown">
                          <div className="dropdown-body">
                            <Select
                              className="w-100"
                              allowClear
                              showSearch
                              placeholder="Select Challenge"
                              options={filteredNames.map((item, index) => ({
                                label: item.name,
                                value: item.id,
                              }))}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              name="userIds"
                              id="userIds"
                              loading={false}
                              value={selectedNameId || null}
                              onChange={(value) => setSelectedNameId(value)} 
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputTypeA">Category</label>
                        <select
                          className="form-control"
                          id="inputType"
                          onChange={handleChangeStreakCategory}
                        >
                          <option value="">Select Category</option>
                          <option value="Special Challenge">Special Challenge</option>
                          <option value="Action Completion">Action Completion</option>
                          <option value="Ranking/Achievement">Ranking/Achievement</option>
                          <option value="Social">Social</option>
                          <option value="Fitness Challenge">Fitness Challenge</option>
                          <option value="Habit Challenge">Habit Challenge</option>
                          <option value="For a cause">For a cause</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAward">Award</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputAward"
                          placeholder="Enter Award"
                          value={badgeAward}
                          onChange={(e) => setBadgeAward(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="inputDescription">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputDescription"
                          placeholder="Enter Description"
                          value={badgeDescription}
                          onChange={(e) => setBadgeDescription(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="badgesImage">Image</label>
                        <input
                          type={"file"}
                          id={"badgesImage"}
                          onChange={handleOnChange}
                          className="form-control"
                        />
                        <img
                          alt=""
                          src={image}
                          width="10%"
                          height="10%"
                          style={imageStyle}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
