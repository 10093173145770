import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./BarGraph.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({ dataset, chartType }) => {
  let teamName = [];
  let graphData = [];
  let toolTipArrayAdd = [];

  const groupDataByRange = (data, ranges) => {
    const groupedData = ranges.map((range) => ({
      range,
      percentage: 0,
    }));

    data.forEach((item) => {
      const { stand_time, user_percentage } = item;
      ranges.forEach((range, index) => {
        const [min, max] = range.split("-").map(Number);

        if (index === ranges.length - 1) {
          if (stand_time >= min) {
            groupedData[index].percentage += user_percentage;
          }
        } else if (stand_time >= min && stand_time <= max) {
          groupedData[index].percentage += user_percentage;
        }
      });
    });

    return groupedData;
  };

  if (dataset?.time_length?.length > 0) {
    const ranges = ["0-32", "33-64", "65-96", "97-128"];
    const groupedData = groupDataByRange(dataset.time_length, ranges);

    groupedData.forEach((group) => {
      teamName.push(group.range);
      graphData.push(group.percentage);
    });
  }

  if (dataset?.time_stood !== undefined && dataset?.time_stood.length > 0) {
    dataset?.time_stood.forEach((el, i) => {
      return (
        teamName.push(`${el?.date}`) && graphData.push(`${el?.user_percentage}`)
      );
    });
  }

  if (dataset?.length > 0 && chartType === "corporateUserReport") {
    dataset?.map((el, i) => {
      return (
        teamName.push(`${el?.date}`) &&
        graphData.push(`${el?.total_stand}`) &&
        toolTipArrayAdd.push(`${el?.total_stand_time}`)
      );
    });
  }

  const xAxisLabels = () => {
    if (dataset?.time_length) {
      return ["0-32", "33-64", "65-96", "97-128"];
    }
    if (dataset?.time_stood) {
      return Array.from({ length: 16 }, (_, i) =>
        i === 15 ? "16" : (i + 1).toString()
      );
    }
    return teamName;
  };

  const xAxisNames = () => {
    if (dataset?.team_avg_stood) {
      return "Teams";
    } else if (dataset?.time_length) {
      return "Minutes Stood";
    } else if (dataset?.time_stood) {
      return "Number of Times Stood per Day";
    } else if (chartType === "corporateUserReport") {
      return "Date";
    }
  };

  const yAxisNames = () => {
    if (dataset?.team_avg_stood) {
      return "Average # of Stands per Team";
    } else if (dataset?.time_length) {
      return "Percentage of Users";
    } else if (dataset?.time_stood) {
      return "Percentage of Users";
    } else if (chartType === "corporateUserReport") {
      return "Total Stands";
    }
  };

  let max = graphData?.length > 0 ? Math.max(...graphData) + 5 : 100;

  const toolTipLabel = (val) => {
    return chartType === "corporateUserReport"
      ? `Total Stand Time - ${toolTipArrayAdd[val?.dataIndex]} Minutes`
      : val?.label;
  };

  const toolTipTitle = (val) => {
    return chartType === "corporateUserReport"
      ? `Total Stands - ${val?.[0]?.formattedValue}`
      : val?.[0]?.formattedValue;
  };

  const options = {
    responsive: true,
    devicePixelRatio: 4,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (xDatapoint) => {
            const title = toolTipTitle(xDatapoint);
            return `${title}%`;
          },
          label: (yDatapoint) => {
            return toolTipLabel(yDatapoint);
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        steps: 10,
        stepValue: 5,
        max: max,
        title: {
          display: true,
          text: yAxisNames(),
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: xAxisNames(),
        },
      },
    },
  };

  const data = {
    labels: xAxisLabels(),
    datasets: [
      {
        data: graphData,
        backgroundColor: "#5BC6D6",
      },
    ],
  };

  return <Bar className="bar-graph" options={options} data={data} />;
};

export default BarGraph;
