import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link,useNavigate } from "react-router-dom";

export default function CharityList() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const navigate = useNavigate();

  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [, setPage] = useState(1);
  const [, setAmessage] = useState("");

  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "name",
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Link to={`/charity/view?id=${row.id}`} className="btn btn-primary btn-sm btn-space-custom">
            Preview
          </Link>
          {1 === +localStorage.getItem("role") ? (
            <>
          <Link
            to={`/charity/edit?id=${row.id}`}
            className="btn btn-info btn-sm btn-space-custom"
          >
            Edit
          </Link>
          <Link
            className='btn btn-danger btn-sm btn-space-custom'
            title='Delete'
            onClick={() => {
              if (window.confirm("Are you sure to delete this Charity? Action cannot be reversed.")) {
                handleDelete(row.id);
              }
            }}>
            Delete
          </Link>
          </>
          ) : (
            ""
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      
    },
  ];

   

  const fetchCharity = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `V1/charity/all?page=${page}&per_page=${perPage}&delay=1`,
        config
      );

      setData(response.data.data);
      setTotalRows(response.data.total);
      setLoading(false);
    }
    catch (err) {
      if (err.response && err.response.status === 401) {
          localStorage.clear();
          navigate("/login"); 
        
      } else {
        console.error('API error:', err.message);
      }
    }

  };

  const handlePageChange = (page) => {
    fetchCharity(page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/charity/all?page=${page}&per_page=${newPerPage}&delay=1`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage = 10, page = 1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL + `V1/charity/all?search=${search}`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleDelete = async (id) => {
    const params = {
      id: id,
    };

    await axios.post(process.env.REACT_APP_API_URL + `V1/charity/delete`, params, config).then((response) => {
      setAmessage(
        <>
          <div>
            <div className='alert alert-danger-custom'>{response.data.message}</div>
          </div>
        </>
      );

      const elementToHide = document.getElementById("row-" + id);
      elementToHide.style.display = "none";

      setTimeout(() => {
        setAmessage("");
      }, 2000);
    });
  };

  useEffect(() => {
    fetchCharity(1); // fetch page 1 of users
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  ////////////////////////////////
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Charities</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Charities</li>
                </ol>
              </div>
            </div>
            {1 === +localStorage.getItem("role") ? (
             <> 
            <div className="row">
              <div className="col-12 mb-2 my-0">
                <Link to="/charity/create">
                  <button className="btn btn-primary">Add New Charity</button>
                </Link>
              </div>
            </div>
            </>
              ) : (
                ""
              )}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => handleSearcFilter(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
