import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

export default function CorporateAdminEdit() {
  const Navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState([]);
  const [successStyle, setSuccessStyle] = useState({
    color: "black",
    display: "none",
  });
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [errorPersonalStyle, setErrorPersonalStyle] = useState({
    color: "white",
    display: "none",
  });
  const [successPersonalStyle, setSuccessPersonalStyle] = useState({
    color: "black",
    display: "none",
  });

  const [successPersonal, setSuccessPersonal] = useState();
  const [errorPersonal, setErrorPersonal] = useState([]);

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
 
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [age, setAge] = useState("");
  const [averageFitness, setAverageFitness] = useState("");
  const [gender, setGender] = useState("");
  const [injury, setInjury] = useState("");
  const handleChangeGender = (e) => setGender(e.target.value);
  const [searchParams] = useSearchParams();
  
  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };
  const getUser = async () => {
    try {
      const response = await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/get-user",
          params,
          config
        )
        .then((response) => response.data)
        .catch((err) => console.error(err));
      setUserId(response.data.id);
      setUserName(response.data.name);
      setUserEmail(response.data.email);
    } catch (err) {
      console.log(err);
    }
  };

  const updateUser = (e) => {
    e.preventDefault();
     
    const params = {
      id: userId,
      name: userName,
      email: userEmail,
      password: userPassword,
      c_password: userConfirmPassword,
    };

    fetch(process.env.REACT_APP_API_URL + "V1/user/update-user", {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("_token"),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
         
        if (res.success) {
          setSuccess(res.message);
          setSuccessStyle({
            color: "black",
            display: "block",
          });
          setErrorStyle({
            color: "red",
            display: "none",
          });
           setTimeout(() => {
            Navigate("/corporate-admin?id="+res.data.corporate_id);
          }, 2000);
        } else {
          setError(res.message);
          setErrorStyle({
            color: "red",
            display: "block",
          });
          setSuccessStyle({
            color: "black",
            display: "none",
          });
        }
      });
    
  };

  const getUserPersonalDetail = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/get-user-personal-detail",
          params,
          config
        )
        .then((response) => {
          if(response.data.data !== null) {

            setHeight(response.data.data.height);
            setWeight(response.data.data.weight);
            setAge(response.data.data.age);
            setAverageFitness(response.data.data.average_fitness);
            setGender(response.data.data.gender);
            setInjury(response.data.data.injury);
          }
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserPersonalDetail = async (e) => {
    e.preventDefault();

    const params = {
      user_id: userId,
      height: height,
      weight: weight,
      age: age,
      average_fitness: averageFitness,
      gender: gender,
      injury: injury,
    };

    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/user/update-user-personal-detail",
          params,
          config
        )
        .then(function (response) {
          if (response.data.success) {
            setSuccessPersonal(response.data.message);
            setSuccessPersonalStyle({
              color: "black",
              display: "block",
            });
            setErrorPersonalStyle({
              color: "red",
              display: "none",
            });
          }
        })
        .catch(function (err) {
          setErrorPersonal(err.response.data.data);
          setErrorPersonalStyle({
            color: "red",
            display: "block",
          });
          setSuccessPersonalStyle({
            color: "black",
            display: "none",
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUser();
    getUserPersonalDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Corporation Admin</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/corporations">Corporation Admin</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Corporation Admin
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card card-primary">
                  <div className="card-body" style={errorStyle}>
                    <h6>Errors</h6>
                    <ul>
                      {(() => {
                        if (error.name) {
                          return <li>{error.name}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.email) {
                          return <li>{error.email}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.password) {
                          return <li>{error.password}</li>;
                        }
                      })()}
                      {(() => {
                        if (error.c_password) {
                          return <li>{error.c_password}</li>;
                        }
                      })()}
                    </ul>
                  </div>
                  <div className="card-body" style={successStyle}>
                    {success}
                  </div>
                  <form onSubmit={updateUser}>
                    <input
                      type="hidden"
                      name="id"
                      value={searchParams.get("id")}
                    />
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputName"
                          placeholder="Enter Name"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter email"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword2">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword2"
                          placeholder="Confirm Password"
                          value={userConfirmPassword}
                          onChange={(e) =>
                            setUserConfirmPassword(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {() => {
              if (!localStorage.getItem("corporateId")) {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <h1 className="m-0">Edit Personal Detail</h1>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card card-primary">
                          <div className="card-body" style={errorPersonalStyle}>
                            <h6>Errors</h6>
                            <ul>
                              {(() => {
                                if (errorPersonal.height) {
                                  return <li>{errorPersonal.height}</li>;
                                }
                              })()}
                              {(() => {
                                if (errorPersonal.weight) {
                                  return <li>{errorPersonal.weight}</li>;
                                }
                              })()}
                              {(() => {
                                if (errorPersonal.age) {
                                  return <li>{errorPersonal.age}</li>;
                                }
                              })()}
                              {(() => {
                                if (errorPersonal.average_fitness) {
                                  return (
                                    <li>{errorPersonal.average_fitness}</li>
                                  );
                                }
                              })()}
                              {(() => {
                                if (errorPersonal.gender) {
                                  return <li>{errorPersonal.gender}</li>;
                                }
                              })()}
                              {(() => {
                                if (errorPersonal.injury) {
                                  return <li>{errorPersonal.injury}</li>;
                                }
                              })()}
                            </ul>
                          </div>
                          <div
                            className="card-body"
                            style={successPersonalStyle}
                          >
                            {successPersonal}
                          </div>
                          <form onSubmit={updateUserPersonalDetail}>
                            <input
                              type="hidden"
                              name="id"
                              value={searchParams.get("id")}
                            />
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputHeight">
                                      Height
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputHeight"
                                      placeholder="Enter Height"
                                      value={height}
                                      onChange={(e) =>
                                        setHeight(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputWeight">
                                      Weight
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputWeight"
                                      placeholder="Enter Weight"
                                      value={weight}
                                      onChange={(e) =>
                                        setWeight(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputWeight">
                                      Age
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputWeight"
                                      placeholder="Enter Age"
                                      value={age}
                                      onChange={(e) => setAge(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputWeight">
                                      Average Fitness
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputWeight"
                                      placeholder="Enter Average Fitness"
                                      value={averageFitness}
                                      onChange={(e) =>
                                        setAverageFitness(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputWeight">
                                      Gender
                                    </label>
                                    <select
                                      className="form-control"
                                      value={gender}
                                      onChange={handleChangeGender}
                                    >
                                      <option value="1">Male</option>
                                      <option value="2">Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputWeight">
                                      Injury
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputWeight"
                                      placeholder="Enter Injury"
                                      value={injury}
                                      onChange={(e) =>
                                        setInjury(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            }}
          </div>
        </div>
      </div>
    </>
  );
}
