import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function AnnouncementEdit() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [announcementTitle, setAnnouncementTitle] = useState();
   const [announcementSubTitle, setAnnouncementSubTitle] = useState();
  const [announcementBody, setAnnouncementBody] = useState();
  const [announcementPic, setAnnouncementPic] = useState();
  const [announcementSetPic, setAnnouncementSetPic] = useState();

  const [searchParams] = useSearchParams();
  const [editImageStyle, setEditImageStyle] = useState({
    display: "block",
  });
  const [imageStyle, setImageStyle] = useState({
    display: "none",
  });
  const [image, setImage] = useState("");

  const updateAnnouncement = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("id", searchParams.get("id"));
    formData.append("title", announcementTitle);
    formData.append("sub_title", announcementSubTitle);
    formData.append("body", announcementBody);
    formData.append("body", announcementBody);
    if (announcementSetPic !== undefined) {
      formData.append("image", announcementSetPic);
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + `V1/announcement/update`,
        formData,
        {
          headers: {
            // Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("_token"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/announcement/all");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        
        console.log(error);
      });
  };

  useEffect(() => {
    getAnnouncement();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = {
    id: searchParams.get("id"),
  };
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const getAnnouncement = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/announcement/detail",
          params,
          config
        )
        .then((response) => {
          setAnnouncementTitle(response.data.data.title);
          setAnnouncementSubTitle(response.data.data.sub_title);
          setAnnouncementBody(response.data.data.body);
          setAnnouncementPic(response.data.data.image);
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnChange = (e) => {
    setAnnouncementPic(e.target.files[0]);
    setAnnouncementSetPic(e.target.files[0])
    setImage(URL.createObjectURL(e.target.files[0]));
    setEditImageStyle({
      display: "none",
    });
    setImageStyle({
      display: "block",
    });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Edit Announcement</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/announcement/all">Announcement</Link>
                </li>
                <li className="breadcrumb-item active">Edit Announcement</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.title) {
                        return <li>{error.title}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.sub_title) {
                        return <li>{error.sub_title}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.image) {
                        return <li>{error.image}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={updateAnnouncement}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Name"
                        value={announcementTitle}
                        onChange={(e) => setAnnouncementTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Sub title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Sub Title"
                        value={announcementSubTitle}
                        onChange={(e) => setAnnouncementSubTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputDescription">Body</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="exampleInputDescription"
                        placeholder="Enter Description"
                        value={announcementBody}
                        onChange={(e) => setAnnouncementBody(e.target.value)}
                        rows={5}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="imputImage">Image</label>
                      <input
                        type={"file"}
                        id={"imputImage"}
                        onChange={handleOnChange}
                        className="form-control"
                      />
                      {(() => {
                          if (announcementPic !== "") {
                            return (
                              <img alt=""
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  "/storage/" +
                                  announcementPic
                                }
                                width="10%"
                                height="10%"
                                style={editImageStyle}
                              />
                            );
                          }
                        })()}
                        <img alt="" src={image} width="10%" height="10%" style={imageStyle} />
                    </div>
                    {() => {
                      if (announcementSetPic !== "") {
                        return (
                          <div className="form-group">
                            <img
                              alt=""
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                "/storage/" +
                                announcementSetPic
                              }
                            />
                          </div>
                        );
                      }
                    }}
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
