/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Datepicker from "react-datepicker";

export default function ChallengeEdit() {
  const Navigate = useNavigate();

  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [challangeName, setChallangeName] = useState("");
  const [challangeStartDate, setChallangeStartDate] = useState();
  const [challangeEndDate, setChallangeEndDate] = useState();
  const [challangeGoal, setChallangeGoal] = useState("");
  const [challangeFrequency, setChallangeFrequency] = useState("");
  const [challangePic, setChallangePic] = useState();
  const [challangePicSet, setChallangePicSet] = useState();
  const [type, setType] = useState("");
  const [challengeDesc, setChallengeDesc] = useState("");
  const [charityId] = useState("");
  const [searchParams] = useSearchParams();
  const [selectedOption, setSelectedOption] = useState(0);
  const [challengeBillName, setChallengeBillName] = useState("");
  const [challengeBillAddress, setChallengeBillAddress] = useState("");
  const [challengeActivityGoal, setChallengeActivityGoal] = useState("");
  const [challengeNumberOfGoal, setChallengeNumberOfGoal] = useState("");

  const updateChallenge = (e) => {
    e.preventDefault();

    let zero = "0";
    let sd = new Date(challangeStartDate);
    let sdm = sd.getMonth() + 1;
    if (sdm < 10) {
      sdm = zero.concat(sdm);
    }
    let startDate = `${sd.getFullYear()}-${sdm}-${sd.getDate()} 00:00:00`;

    let ed = new Date(challangeEndDate);
    let edm = ed.getMonth() + 1;
    if (edm < 10) {
      edm = zero.concat(edm);
    }
    let endDate = `${ed.getFullYear()}-${edm}-${ed.getDate()} 00:00:00`;

    let formData = new FormData();
    if (challangePic !== undefined) {
      formData.append("image", challangePic);
    }

    formData.append("name", challangeName);
    formData.append("id", searchParams.get("id"));
    formData.append("charity", charityId);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("goal", challangeGoal);
    formData.append("frequency", challangeFrequency);
    formData.append("description", challengeDesc);
    formData.append("activity_goal", challengeActivityGoal);
    formData.append("no_of_goal", challengeNumberOfGoal);
    formData.append("paymentmethod", selectedOption);
    if (selectedOption === 1) {
      formData.append("billname", challengeBillName);
      formData.append("billaddress", challengeBillAddress);
    }

    axios
      .post(process.env.REACT_APP_API_URL + `V1/challenge/update`, formData, {
        headers: {
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("_token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/challenges");
        }, 2000);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });

        console.log(error);
      });
  };

  useEffect(() => {
    getChallenge();
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    setChallangeGoal(e.target.value);
  };

  const params = {
    id: searchParams.get("id"),
  };

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const getChallenge = async () => {
    try {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "V1/challenge/get-challenge",
          params,
          config
        )
        .then((response) => {
          setChallangeName(response.data.data.name);
          setChallangePicSet(response.data.data.image);
          if (response.data.data.start_date) {
            setChallangeStartDate(new Date(response.data.data.start_date));
          } else {
            setChallangeStartDate();
          }

          if (response.data.data.end_date) {
            setChallangeEndDate(new Date(response.data.data.end_date));
          } else {
            setChallangeEndDate();
          }

          setChallangeGoal(response.data.data.goal);
          setChallangeFrequency(response.data.data.frequency);
          setType(response.data.data.type);
          setChallengeDesc(response.data.data.description);
          setSelectedOption(response.data.data.paymentmethod);
          setChallengeBillName(response.data.data.billname);
          setChallengeBillAddress(response.data.data.billaddress);
          setChallengeActivityGoal(response.data.data.activity_goal);
          setChallengeNumberOfGoal(response.data.data.no_of_goal);
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnChange = (e) => {
    setChallangePic(e.target.files[0]);
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Edit challenge</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/challenges">Challenge</Link>
                </li>
                <li className="breadcrumb-item active">Edit challenge</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.name) {
                        return <li>{error.name}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.image) {
                        return <li>{error.image}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.start_date) {
                        return <li>{error.start_date}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.end_date) {
                        return <li>{error.end_date}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={updateChallenge}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName"
                        placeholder="Enter Name"
                        value={challangeName}
                        onChange={(e) => setChallangeName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputImage">Image</label>
                      <input
                        type={"file"}
                        id={"exampleInputImage"}
                        onChange={handleOnChange}
                        className="form-control"
                      />
                      {(() => {
                        if (challangePicSet !== "") {
                          return (
                            <img
                              alt=""
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                "/storage/" +
                                challangePicSet
                              }
                              width="30%"
                              height="40%"
                            />
                          );
                        }
                      })()}
                    </div>
                    {2 === +localStorage.getItem("role") ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="exampleInputStartDate">
                            Start Date
                          </label>
                          <Datepicker
                            id="exampleInputStartDate"
                            className="form-control"
                            wrapperClassName="datePicker"
                            selected={challangeStartDate}
                            onChange={(date) => setChallangeStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEndDate">End Date</label>
                          <Datepicker
                            id="exampleInputStartDate"
                            className="form-control"
                            wrapperClassName="datePicker"
                            selected={challangeEndDate}
                            onChange={(date) => setChallangeEndDate(date)}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {type === 1 || type === 4 ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="exampleInputGoal">Goal ($)</label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputGoal"
                            placeholder="Goal"
                            value={challangeGoal}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <label htmlFor="exampleInputFrequency">Description</label>
                      <textarea
                        className="form-control"
                        id="exampleInputDescription"
                        placeholder="Description"
                        value={challengeDesc}
                        onChange={(e) => setChallengeDesc(e.target.value)}
                      />
                    </div>
                    {type === 2 || type === 3 ? (
                      <>
                      <div className="form-group">
                          <label htmlFor="exampleInputFrequency">
                            Activity Goal{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputName"
                            placeholder="Enter Activity Goal"
                            value={challengeActivityGoal}
                            onChange={(e) => setChallengeActivityGoal(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputFrequency">
                            No. of Goal
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputName"
                            placeholder="Enter No. of Goal"
                            value={challengeNumberOfGoal}
                            onChange={(e) => setChallengeNumberOfGoal(e.target.value)}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {type === 1 || type === 4 ? (
                      <>
                      <div className="form-group">
                      <label htmlFor="exampleInputFrequency">
                        Payment Method For Donation
                      </label>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkOption"
                          checked={selectedOption === 1}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setSelectedOption(checked ? 1 : 0);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkOption"
                        >
                          Check
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="creditCardOption"
                          checked={selectedOption === 2}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setSelectedOption(checked ? 2 : 0);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="creditCardOption"
                        >
                          Credit Card
                        </label>
                      </div>

                      {selectedOption === 1 && (
                        <div>
                          <h3 className="mb-4 mt-4">Billing Information</h3>
                          <div className="form-group">
                            <label htmlFor="billingName">Billing Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="billingName"
                              placeholder="Enter Billing Name"
                              value={challengeBillName}
                              onChange={(e) =>
                                setChallengeBillName(e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="billingAddress">
                              Billing Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="billingAddress"
                              placeholder="Enter Billing Address"
                              value={challengeBillAddress}
                              onChange={(e) =>
                                setChallengeBillAddress(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                      </>
                    ) : (
                      ""
                    )}
                    
                    {() => {
                      if (challangePic !== "") {
                        return (
                          <div className="form-group">
                            <img
                              alt=""
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                "/storage/" +
                                challangePic
                              }
                            />
                          </div>
                        );
                      }
                    }}
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
