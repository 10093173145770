import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Reports.css";
import { getTeamGlanceReport } from "../../Api/EndpointCalls/EndpointCalls";

const Reports = () => {
  const [teamGlanceData, setTeamGlanceData] = useState({ totalUsersActive: "", totalUsersInChallenge: "", percDailyStandGoal: "", loading: true });

  const corporateId = localStorage.getItem("corporateId");
  const userId = localStorage.getItem("id");

  const navigate = useNavigate();

  const fetchTeamGlanceReport = async () => {
    try {
      const res = await getTeamGlanceReport(corporateId, userId);
      if (res?.status === 200) {
        setTeamGlanceData((v) => ({
          ...v,
          loading: false,
          totalUsersActive: res?.data?.data?.total_user_active,
          totalUsersInChallenge: res?.data?.data?.total_user_in_challenge,
          percDailyStandGoal: res?.data?.data?.percentage_daily_stand_goal,
        }));
      }
    } catch (error) {}
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "2") {
      fetchTeamGlanceReport();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {localStorage.getItem("role") === "2" ? (
        <>
          <div className='content-wrapper'>
            <div className='container-fluid p-4'>
              {/* Top Row - Cards */}
              <h4 className='text-center'>View Reports</h4>
              <div className='row'>
                {/* Challenges At a Glance */}
                <div className='col-xl-3 col-md-3 mb-4'>
                  <div className='card border-top-users-primary shadow h-100 py-2 m-0'>
                    <div className='col-auto justify-content-center'>
                      <div className='d-flex flex-column justify-content-center'>
                        <span className='fa-stack fa-lg align-self-center mb-2'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "#4e73df" }}></i>
                          <i className='fa fa-tasks fa-stack-1x fa-inverse'></i>
                        </span>
                        <p className='align-self-center font-weight-bold'>CHALLENGES AT A GLANCE</p>
                      </div>
                    </div>
                    <div className='row no-gutters align-items-center d-flex justify-content-between'>
                      <div className='col m-1 d-flex justify-content-around'>
                        <button
                          className='btn'
                          style={{ backgroundColor: "#4e73df", color: "white", border: "none" }}
                          onClick={() => {
                            navigate("/challenges", { state: { type: "at_a_glance" } });
                          }}>
                          View Challenges
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Teams at a Glance */}
                <div className='col-xl-6 col-md-6 mb-4'>
                  <div className='card border-top-users-active-in-chalenge-primary shadow h-100 py-2 m-0'>
                    <div className='col-auto justify-content-center'>
                      <div className='d-flex flex-column justify-content-center'>
                        <span className='fa-stack fa-lg align-self-center mb-2'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                          <i className='fa fa-users fa-stack-1x fa-inverse'></i>
                        </span>
                        <p className='align-self-center font-weight-bold'>TEAMS AT A GLANCE</p>
                      </div>
                    </div>
                    <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                      <div className='col m-1'>
                        <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Total Users in Challenge</div>
                        <div className='h5 mb-0 font-weight-bold text-gray-800'>
                          {teamGlanceData?.loading === false ? teamGlanceData?.totalUsersInChallenge || "N/A" : "Loading..."}
                        </div>
                      </div>
                      <div className='col m-1'>
                        <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Total Users Active</div>
                        <div className='h5 mb-0 font-weight-bold text-gray-800'>
                          {teamGlanceData?.loading === false ? teamGlanceData?.totalUsersActive : "Loading..."}
                        </div>
                      </div>
                      <div className='col m-1'>
                        <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>daily stand goal(%)</div>
                        <div className='h5 mb-0 font-weight-bold text-gray-800'>
                          {teamGlanceData?.loading === false ? Number(teamGlanceData?.percDailyStandGoal).toFixed(2) + "%" || "N/A" : "Loading..."}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Overall Challenge Report Card */}
                <div className='col-xl-3 col-md-3 mb-4'>
                  <div className='card border-top-users-daily-goal-primary shadow h-100 py-2 m-0'>
                    <div className='col-auto justify-content-center'>
                      <div className='d-flex flex-column justify-content-center'>
                        <span className='fa-stack fa-lg align-self-center mb-2'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "rgb(0, 155, 132)" }}></i>
                          <i className='fa fa-file-alt fa-stack-1x fa-inverse'></i>
                        </span>
                        <p className='align-self-center font-weight-bold text-uppercase'>Overall Challenge Report</p>
                      </div>
                    </div>
                    <div className='row no-gutters align-items-center d-flex justify-content-between'>
                      <div className='col m-1 d-flex justify-content-around'>
                        <button
                          className='btn btn-primary'
                          style={{ backgroundColor: "rgb(0, 155, 132)", color: "white", border: "none" }}
                          onClick={() => {
                            navigate("/challenges", { state: { type: "overall" } });
                          }}>
                          View Challenges
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Achievements */}
                <div className='col-xl-3 col-md-3 mb-4'>
                  <div className='card border-top-users-one shadow h-100 py-2 m-0'>
                    <div className='col-auto justify-content-center'>
                      <div className='d-flex flex-column justify-content-start'>
                        <span className='fa-stack fa-lg align-self-center mb-2'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                          <i className='fa fas fa-medal fa-stack-1x fa-inverse'></i>
                        </span>
                        <p className='align-self-center font-weight-bold text-uppercase'>Achievements Report</p>
                      </div>
                    </div>
                    <div className='row no-gutters align-items-center d-flex justify-content-between'>
                      <div className='col m-1 d-flex justify-content-around'>
                        <button
                          className='btn btn-primary'
                          style={{ backgroundColor: "#f36a26", color: "white", border: "none" }}
                          onClick={() => {
                            navigate("/challenges", { state: { type: "achievements" } });
                          }}>
                          View Challenges
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='content-wrapper'>
            <div className='container-fluid p-4'>
              {/* Top Row - Cards */}
              <h4 className='text-center'>View Reports</h4>
              <div className='row'>
                {/* Challenges At a Glance */}
                <div className='col-xl-3 col-md-3 mb-4'>
                  <div className='card border-top-users-primary shadow h-100 py-2 m-0'>
                    <div className='col-auto justify-content-center'>
                      <div className='d-flex flex-column justify-content-center'>
                        <span className='fa-stack fa-lg align-self-center mb-2'>
                          <i className='fa fa-circle fa-stack-2x' style={{ color: "#4e73df" }}></i>
                          <i className='fa fa-tasks fa-stack-1x fa-inverse'></i>
                        </span>
                        <p className='align-self-center font-weight-bold'>QUICK GLANCE</p>
                      </div>
                    </div>
                    <div className='row no-gutters align-items-center d-flex justify-content-between'>
                      <div className='col m-1 d-flex justify-content-around'>
                        <button
                          className='btn'
                          style={{ backgroundColor: "#4e73df", color: "white", border: "none" }}
                          onClick={() => {
                            navigate("/corporates", { state: { type: "corporation_report" } });
                          }}>
                          View Corporations
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
