import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { downloadAsPDF } from "../../../Utils/HelperFunctions";
import BarGraph from "../../../GraphComponents/BarGraph/BarGraph";
import { getIndividualUserReport } from "../../../Api/EndpointCalls/EndpointCalls";

const CorporateUserReport = () => {
  const [reportData, setReportData] = useState({ data: [], loading: false });
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dateRange, setDateRange] = useState({ fromDate: new Date(), toDate: new Date() });

  const params = useParams();
  const userId = localStorage.getItem("id");

  const fetchCorporateUserReportData = async (userID, corporateUserId, from_date, to_date) => {
    try {
      const res = await getIndividualUserReport(userID, corporateUserId, from_date, to_date);
      if (res?.status === 200) {
        setReportData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  

  const handleDateRange = (date, type) => {
    setDateRange((v) => ({ ...v, [type]: date }));
  };

  const handleApplyDateFilter = () => {
    setOpenDateRange(!openDateRange);
    fetchCorporateUserReportData(
      userId,
      params?.corporateUserId,
      moment(dateRange?.fromDate).format("YYYY-MM-DD"),
      moment(dateRange?.toDate).format("YYYY-MM-DD")
    );
  };

  useEffect(() => {
    fetchCorporateUserReportData(
      userId,
      params?.corporateUserId,
      moment(dateRange?.fromDate).format("YYYY-MM-DD"),
      moment(dateRange?.toDate).format("YYYY-MM-DD")
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='content-wrapper p-4'>
      <div className='row'>
        <div className='col d-flex justify-content-end'>
          <button
            className='download-report-pdf-button btn btn-primary'
            onClick={() => {
              downloadAsPDF(document.getElementById("content-report"));
            }}>
            <div className='row'>
              <i className='fa fa-file-download fa-inverse download-pdf-icon'></i>
              <p className='pe-1 m-0'>Download PDF</p>
            </div>
          </button>
        </div>
      </div>
      <div className='' id='content-report'>
        <h4 className='text-center mb-3'>Individual User Report</h4>
        <div className='card border-top-yellow'>
          <div className='card-body'>
            <div className='col-md-14'>
              <div className='row no-gutters align-items-center mb-2 p-2'>
                <div className='col-md-4 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Corporate User id</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.individual_user_id ? reportData?.data?.individual_user_id : "N/A"}
                  </div>
                </div>
                <div className='col-md-4 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Name</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.individual_user_name ? reportData?.data?.individual_user_name : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 col-xl-4 d-flex flex-column justify-content-around'>
            <div className='card shadow border-top-users-active-in-chalenge-primary py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#7a3ec3" }}></i>
                    <i className='fa fa-tasks fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>TOTAL COMPLETED CHALLENGES</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                    {reportData?.data?.total_completed_challenge ? reportData?.data?.total_completed_challenge : "0"}
                  </div>
                </div>
              </div>
            </div>
            <div className='card shadow border-top-users-primary py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#4e73df" }}></i>
                    <i className='fa fa-id-badge fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>BADGES</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                    {reportData?.data?.badges ? reportData?.data?.badges : "0"}
                  </div>
                </div>
              </div>
            </div>
            <div className='card shadow border-top-users-one py-2'>
              <div className='col-auto justify-content-center'>
                <div className='d-flex flex-column justify-content-center'>
                  <span className='fa-stack fa-lg align-self-center mb-2'>
                    <i className='fa fa-circle fa-stack-2x' style={{ color: "#f36a26" }}></i>
                    <i className='fa fas fa-medal fa-stack-1x fa-inverse'></i>
                  </span>
                  <p className='align-self-center font-weight-bold'>GOALS ACHIEVED</p>
                </div>
              </div>
              <div className='row no-gutters align-items-center card-inside-card d-flex justify-content-between'>
                <div className='col m-1'>
                  <div className='h5 mb-0 font-weight-bold text-gray-800 text-center'>
                    {reportData?.data?.goal_achieve ? reportData?.data?.goal_achieve.toUpperCase() : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-8 col-xl-8'>
            <div className='card shadow border-top-users-daily-goal-primary'>
              <div className='card-body'>
                <h4 className='text-center'>Total # of Stands</h4>
                {!openDateRange && (
                  <button
                    className='btn btn-info m-2'
                    onClick={() => {
                      setOpenDateRange(!openDateRange);
                    }}>
                    Select Date
                  </button>
                )}
                {openDateRange === true ? (
                  <div className='row'>
                    <div className='col d-flex justify-content-around align-items-center'>
                      <label htmlFor='exampleInputEndDate'>From Date</label>
                      <ReactDatePicker
                        id='exampleInputStartDate'
                        className='form-control'
                        selected={dateRange?.fromDate}
                        onChange={(date) => handleDateRange(date, "fromDate")}
                        dateFormat='yyyy-MM-dd'
                        autoComplete='off'
                        maxDate={moment().toDate()}
                      />
                      <label htmlFor='exampleInputEndDate'>To Date</label>
                      <ReactDatePicker
                        id='exampleInputStartDate'
                        className='form-control'
                        selected={dateRange?.toDate}
                        onChange={(date) => handleDateRange(date, "toDate")}
                        dateFormat='yyyy-MM-dd'
                        autoComplete='off'
                        maxDate={moment().toDate()}
                        minDate={dateRange?.fromDate}
                      />
                      <button
                        className='btn btn-info m-2'
                        onClick={() => {
                          handleApplyDateFilter();
                        }}>
                        Apply
                      </button>
                      <button
                        className='btn btn-info m-2'
                        onClick={() => {
                          setOpenDateRange(!openDateRange);
                        }}>
                        Close
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className='row p-2'>
                  <BarGraph dataset={reportData?.data?.stood} chartType={"corporateUserReport"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateUserReport;
