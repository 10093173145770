import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

export default function StreaksList() {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("_token"),
    },
  };

  const navigate = useNavigate();
  ////////////////////////////////
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [message] = useState("");
  
  const columns = [
    {
      id: "id",
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      id: "user",
      name: "User",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      id: "challenge",
      name: "Challenge",
      selector: (row) => row.challenge,
      sortable: true,
    },
    {
      id: "amount",
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      id: "status",
      name: "Payment Date",
      selector: (row) =>moment(row.created_at).format('YYYY-MM-DD'),
      sortable: true,
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
  ];

  const fetchBadges = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `V1/billing/list?page=${page}&per_page=${perPage}&delay=1`,
        config
      );

      setData(response.data.data);
      setTotalRows(response.data.total);
      setLoading(false);
    }
    catch (err) {
      if (err.response && err.response.status === 401) {
          localStorage.clear();
          navigate("/login"); 
        
      } else {
        console.error('API error:', err.message);
      }
    }
  };

  const handlePageChange = (page) => {
    fetchBadges(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/billing/list?page=${page}&per_page=${newPerPage}&delay=1`,
      config
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleSearcFilter = async (search, newPerPage=10, page=1) => {
    setLoading(true);

    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `V1/billing/list?page=${page}&per_page=10&delay=1&search=${search}`,
      config
    );
    setTotalRows(response.data.total);
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

 

  useEffect(() => {
    fetchBadges(1); // fetch page 1 of Streaks
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  ////////////////////////////////
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Billing</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Billing</li>
                </ol>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
              <div className="row">
                      <div className="col-md-12">
                         {message} 
                      </div>
                      </div>
                <div className="row">
                     
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => handleSearcFilter(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                     
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      // defaultSortFieldId="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
}
