import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getOverallChallengeReport } from "../../../Api/EndpointCalls/EndpointCalls";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { downloadAsPDF } from "../../../Utils/HelperFunctions";

const OverallChallengeReport = () => {
  const [reportData, setReportData] = useState({ data: null, loading: true });
  const params = useParams();

  const userId = localStorage.getItem("id");
  const corporateId = localStorage.getItem("corporateId");

  const fetchChallengeReport = async () => {
    try {
      const res = await getOverallChallengeReport(params?.challengeId, userId, corporateId);
      setReportData({ data: res?.data?.data, loading: false });
    } catch (error) {}
  };

  const columnsTeamGoalAchieve = [
    {
      id: "id",
      name: "Id",
      selector: (row, i) => row.depart_id,
      sortable: true,
    },
    {
      id: "teamName",
      name: "Department Name",
      selector: (row) => row.depart_name,
      // sortable: true,
    },
    {
      id: "goalAchieve",
      name: "Goal Achieve",
      selector: (row) => row.goal_achieve.charAt(0).toUpperCase() + row.goal_achieve.slice(1),
      sortable: true,
    },
    {
      id: "goalAchieve",
      name: "Goal Achieve %",
      selector: (row) => row.goal_per,
      sortable: true,
    },
  ];

  const columnsTeamStandTime = [
    {
      id: "id",
      name: "Id",
      selector: (row, i) => row.depart_id,
      sortable: true,
    },
    {
      id: "teamName",
      name: "Department Name",
      selector: (row) => row.depart_name,
      // sortable: true,
    },
    {
      id: "teamStandTime",
      name: "Stand Time",
      selector: (row) => row.stand_time + ` Minutes`,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchChallengeReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='content-wrapper p-4'>
      <div className='row'>
        <div className='col d-flex justify-content-end'>
          <button
            className='download-report-pdf-button btn btn-primary'
            onClick={() => {
              downloadAsPDF(document.getElementById("content-report"));
            }}>
            <div className='row'>
              <i className='fa fa-file-download fa-inverse download-pdf-icon'></i>
              <p className='pe-1 m-0'>Download PDF</p>
            </div>
          </button>
        </div>
      </div>
      <div className='' id='content-report'>
        <h4 className='text-center mb-3'>Overall Challenge Report</h4>
        {/* Top Row - Data */}
        <div className='card shadow  border-top-users-primary'>
          <div className='card-body'>
            <div className='col-md-14'>
              <div className='row no-gutters align-items-center mb-2 p-2'>
                <div className='col-md-3 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Challenge Id</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>{params?.challengeId ? params?.challengeId : "N/A"}</div>
                </div>
                <div className='col-md-3 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>NAME</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.challenge_name ? reportData?.data?.challenge_name : "N/A"}
                  </div>
                </div>
                <div className='col-md-3 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Goal Achieved</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>
                    {reportData?.data?.overall_goal_achieve
                      ? `${reportData?.data?.overall_goal_achieve.toUpperCase()}(${reportData?.data?.overall_goal_achieve_per}%)`
                      : "N/A"}
                  </div>
                </div>
                <div className='col-md-3 m-0'>
                  <div className='text-xs font-weight-bold text-black text-uppercase mb-1'>Stand Time</div>
                  <div className='h5 mb-0 font-weight-bold text-gray-800'>{reportData?.data?.stand_time ? reportData?.data?.stand_time : "N/A"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Row - Tables */}
        <div className='card shadow  border-top-users-daily-goal-primary'>
          <div className='card-body'>
            <h4 className='text-center'>Team Goal Achieve</h4>
            <div className='row'>
              <DataTable
                columns={columnsTeamGoalAchieve}
                data={reportData?.data?.team_goal_achieve}
                // progressPending={reportData?.loading}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
              />
            </div>
          </div>
        </div>
        <div className='card shadow  border-top-yellow'>
          <div className='card-body'>
            <h4 className='text-center'>Team Stand Time</h4>
            <div className='row'>
              <DataTable
                columns={columnsTeamStandTime}
                data={reportData?.data?.team_stand_time}
                // progressPending={reportData?.loading}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallChallengeReport;
