import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function AnnouncementCreate() {
  const Navigate = useNavigate();
  const [error, setError] = useState([]);
  const [errorStyle, setErrorStyle] = useState({
    color: "white",
    display: "none",
  });
  const [success, setSuccess] = useState("");
  const [successStyle, setSuccessStyle] = useState({
    color: "white",
    display: "none",
  });

  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementSubTitle, setAnnouncementSubTitle] = useState("");
  const [announcementBody, setAnnouncementBody] = useState();
  const [announcementPic, setAnnouncementPic] = useState("");
  const [image, setImage] = useState("");
  const [imageStyle, setImageStyle] = useState({
    display: "none",
  });

  const createNewAnnouncement = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("title", announcementTitle);
    formData.append("sub_title", announcementSubTitle);
    formData.append("body", announcementBody);
    formData.append("body", announcementBody);
    formData.append("image", announcementPic);

    axios
      .post(
        process.env.REACT_APP_API_URL + `V1/announcement/create`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_token"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        
        setSuccess(response.data.message + ", redirecting to listing...");
        setErrorStyle({
          color: "red",
          display: "none",
        });
        setSuccessStyle({
          color: "black",
          display: "block",
        });

        setTimeout(() => {
          Navigate("/announcement/all");
        }, 2000);
      })
      .catch((error) => {       
        setError(error.response.data.message);
        setErrorStyle({
          color: "red",
          display: "block",
        });
        setSuccessStyle({
          color: "black",
          display: "none",
        });
        
        console.log(error);
      });
  };

  const handleOnChange = (e) => {
    setAnnouncementPic(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageStyle({
      display: "block",
    });
  };
 

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Announcement</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/announcement">Announcement</Link>
                </li>
                <li className="breadcrumb-item active">Create Announcement</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-primary">
                <div className="card-body" style={errorStyle}>
                  <h6>Errors</h6>
                  <ul>
                    {(() => {
                      if (error.title) {
                        return <li>{error.title}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.sub_title) {
                        return <li>{error.sub_title}</li>;
                      }
                    })()}
                    {(() => {
                      if (error.body) {
                        return <li>{error.body}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <div className="card-body" style={successStyle}>
                  <h6>Success</h6>
                  <ul>
                    {(() => {
                      if (success) {
                        return <li>{success}</li>;
                      }
                    })()}
                  </ul>
                </div>
                <form onSubmit={createNewAnnouncement}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputTitle">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputTitle"
                        placeholder="Enter Title"
                        value={announcementTitle}
                        onChange={(e) => setAnnouncementTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputTitle">Sub title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputTitle"
                        placeholder="Enter sub title"
                        value={announcementSubTitle}
                        onChange={(e) => setAnnouncementSubTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputBody">Body</label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="inputBody"
                        placeholder="Enter Body"
                        value={announcementBody}
                        onChange={(e) => setAnnouncementBody(e.target.value)}
                        rows={5}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="imputImage">Image</label>
                      <input
                        type={"file"}
                        id={"imputImage"}
                        onChange={handleOnChange}
                        className="form-control"
                      />
                      <img alt="" src={image} width="10%" height="10%" style={imageStyle} />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
